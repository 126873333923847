// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ask-answer-js": () => import("./../../../src/pages/ask-answer.js" /* webpackChunkName: "component---src-pages-ask-answer-js" */),
  "component---src-pages-ask-app-js": () => import("./../../../src/pages/ask-app.js" /* webpackChunkName: "component---src-pages-ask-app-js" */),
  "component---src-pages-ask-balance-js": () => import("./../../../src/pages/ask-balance.js" /* webpackChunkName: "component---src-pages-ask-balance-js" */),
  "component---src-pages-ask-contact-js": () => import("./../../../src/pages/ask-contact.js" /* webpackChunkName: "component---src-pages-ask-contact-js" */),
  "component---src-pages-ask-first-checkout-js": () => import("./../../../src/pages/ask-first-checkout.js" /* webpackChunkName: "component---src-pages-ask-first-checkout-js" */),
  "component---src-pages-ask-forgot-password-js": () => import("./../../../src/pages/ask-forgot-password.js" /* webpackChunkName: "component---src-pages-ask-forgot-password-js" */),
  "component---src-pages-ask-login-js": () => import("./../../../src/pages/ask-login.js" /* webpackChunkName: "component---src-pages-ask-login-js" */),
  "component---src-pages-ask-signup-js": () => import("./../../../src/pages/ask-signup.js" /* webpackChunkName: "component---src-pages-ask-signup-js" */),
  "component---src-pages-ask-update-profile-js": () => import("./../../../src/pages/ask-update-profile.js" /* webpackChunkName: "component---src-pages-ask-update-profile-js" */),
  "component---src-pages-checkout-pay-incomplete-js": () => import("./../../../src/pages/checkout/pay-incomplete.js" /* webpackChunkName: "component---src-pages-checkout-pay-incomplete-js" */),
  "component---src-pages-checkout-pay-success-js": () => import("./../../../src/pages/checkout/pay-success.js" /* webpackChunkName: "component---src-pages-checkout-pay-success-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

